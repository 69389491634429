import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Gerard Fajardo | Web Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Personal protfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Gerard Fajardo',
  subtitle: "I'm a fullstack web developer based out of",
  location: 'Vancouver, BC',
  cta: '',
  specialties: 'Javascript / React / Node.js',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "Hello! My name is Gerard and I'm a fullstack web developer! When I discovered my passion for software development I've made it my goal to expand my skills from front-end and UI/UX to back-end development. Since then I’ve graduated from Thinkful’s fullstack web development bootcamp, and have gained some experience working at a small startup company. ",
  paragraphTwo: 'Technologies I’ve Used:',
  paragraphThree: 'React, Redux, Node.js, Express.js, MongoDB, Mongoose, HTML5, CSS3',
  resume: 'https://drive.google.com/open?id=1_f7qFi1xzMNXG9nkLH27B3MfM61wbLJK', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'My Fit Journal.jpg',
    title: 'MyFitJournal',
    info:
      'Interactive, daily workout planner that enables users to log and plan their daily workout routines.',
    info2: '',
    url: 'https://myfitjournal.herokuapp.com/',
    repo: 'https://github.com/gthedevv/myfitjournal', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'My Book Reviews.jpg',
    title: 'My Book Reviews',
    info:
      'An app targeted to bookworms that enable users to post reviews about books that they have read, and share it online.',
    info2: '',
    url: 'https://my-bookreviews.herokuapp.com/',
    repo: 'https://github.com/gthedevv/mybookreviews', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'scoops.io.png',
    title: 'Scoops (v1)',
    info:
      'Scoops version 1 is a third party plugin that provides a sleek, contextual website survey solution which lets you learn from your users without making them leave from ugly pop-ups.',
    info2:
      'Note: This version of the web application is no longer live, and the company currently exploring other avenues.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'scoops.io-v2.png',
    title: 'Scoops (v2)',
    info:
      'Scoops version 2 is a remote job board for designers where they can receive email alerts for new design jobs that are catered to the type of position they are looking for.',
    info2:
      'Note: This application is no longer in development. However link to the website is still live to view.',
    url: 'https://scoops.io/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'gerard@g-labs.dev',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://twitter.com/gthedev',
    },
    // {
    //   id: uuidv1(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/gerard-fajardo/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/gedfaj',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
